.new_dezin_label {
    font-size: 14px;
    font-weight: 600;
}

.css-hvtc1a {
    width: 34% !important;
    border-radius: 10px;
}

.margin_fix {
    margin: 8px 0px;
}

.w-100 {
    width: 100% !important
}

.btn-newdezine {
    background-color: black !important;
    color: white !important;
    width: 100% !important;
    border-radius: 7px !important;
    text-align: center !important;
    padding: 10px 20px !important;
    cursor: pointer;
}

.margin-top-20px {
    margin-top: 20px;
}

.font14 {
    font-size: 14px;
}

.selectstyle {
    padding: 14px !important;
    border-radius: 5px !important;
    border-color: #d2d6da;
}

.text-right {
    text-align: right;
}

.lockininput {
    position: absolute;
    right: 10px;
}

.font-13 {
    font-size: 13px;
}