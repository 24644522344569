:root {
  --brand-list-item-bg: linear-gradient(195deg, #49a3f1, #1a73e8);
  --brand-sidnav-bg: linear-gradient(195deg, #42424a, #191919);
  --brand-button-bg: linear-gradient(195deg, #49a3f1, #1a73e8);
  --brand-button-text: #fff;
  --brand-card-text: #fff;
  --brand-sidnav-list-hover: rgba(255, 255, 255, 0.2);
  --brand-card-bg: linear-gradient(195deg, #49a3f1, #1a73e8);
}
a.brand-list-item > li > div:hover {
  background: var(--brand-sidnav-list-hover) !important;
}
a.brand-list-item.active > li > div {
  background: var(--brand-list-item-bg) !important;
}
.brand-buttons {
  background: var(--brand-button-bg) !important;
  color: var(--brand-button-text) !important;
}
.brand-buttons > a {
  color: var(--brand-button-text) !important;
}
.brand-card-background {
  background: var(--brand-card-bg) !important;
  box-shadow: 0rem 0.25rem 1.25rem 0rem rgba(0, 0, 0, 0.14) !important;
}
.brand-card-text {
  color: var(--brand-card-text) !important;
}
