.custom-navbar{
    display: flex !important;
    justify-content: flex-start !important;
}
a.testing.active>li>div>li{
    background: linear-gradient(195deg, #49a3f1, #1A73E8) !important;
    color: #fff;
}
a.true>li>div>div{
    color:#1A73E8 ;
    text-decoration: underline;
}
.navbar-settings:hover{
    outline: 2px solid green;
}
.navbar-settings{
    background: rgba(255, 255, 255, 0.8) !important;
}